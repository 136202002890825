<template>
  <section
    :class="$style.root"
    v-show="consentSetting !== false && consentSetting !== true"
    id="cookie-consent"
  >
    <div :class="$style.content">
      <div>
         {{ $t('cookies.message') }}
      </div>
      <button
        :class="$style.button"
        @click="[grantGtagConsent()]"
      >
        {{ $t('cookies.button.accept') }}
      </button>
      <button
        :class="$style.button"
        @click="[revokeGtagConsent()]"
      >
        {{ $t('cookies.button.revoke') }}
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
  const { gtag, grantConsent, revokeConsent } = useGtag()
  const consentSetting = useCookie('gtagConsent', { maxAge: 60 * 60 * 24 * 7 })

  const grantGtagConsent = () => {
    grantConsent()
    consentSetting.value = true
  }

  const revokeGtagConsent = () => {
    revokeConsent()
    consentSetting.value = false
  }

  onMounted(() => {
    if(consentSetting.value === true) {
      grantConsent()
    }
  })
</script>

<style module>
.root {
  composes: font-size-small from global;
  position: fixed;

  right: 0;
  bottom: 0;
  left: 0;

  z-index: var(--z-index--cookie-consent);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  /* backdrop-filter: var(--backdrop-filter--light) grayscale(1); */

  pointer-events: none;
}

.content {
  padding: calc(var(--unit--vertical) * 1.2) var(--unit--horizontal) var(--unit--vertical) var(--unit--horizontal);

  background-color: var(--color--black);
  color: var(--color--white);
  --color--secondary: var(--color--gray);

  display: flex;
  flex-wrap: wrap;
  gap: 1ch;

  pointer-events: all;
}

.button {
  composes: reset-button link from global;
}
</style>

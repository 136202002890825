import qs from 'qs'

const fields = [
  'slug'
]

export const queryBiennialThumbnailSelected = (options: QueryBySlugOrIdOptions) => {
  const query = qs.stringify(
    {
      populate: {
        currentBiennialEntry: {
          fields: [
            'slug'
          ]
        },
        lastBiennialEntry: {
          fields: [
            'dateEnd',
            'editionIndex'
          ]
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-page?${query}`
}

<template>
  <div
    :class="$style.root"
    tabindex="0"
    ref="element"
    @keyup.left="onKeyDownCycle('prev')"
    @keyup.right="onKeyDownCycle('next')"
  >
    <slot />

    <transition name="button-right">
      <i18nLink
        v-if="prevId"
        :class="$style.button"
        dir="prev"
        :to="getLightBoxImageIdQueryParams(prevId)"
      >
        <div :class="$style.buttonInner">
          <SvgoArrow :class="$style.svg" />
        </div>
      </i18nLink>
    </transition>

    <transition name="button-left">
      <i18nLink
        v-if="nextId"
        :class="$style.button"
        dir="next"
        :to="getLightBoxImageIdQueryParams(nextId)"
      >
        <div :class="$style.buttonInner">
          <SvgoArrow :class="$style.svg" />
        </div>
      </i18nLink>
    </transition>
  </div>
</template>

<script setup>
import { omit } from 'lodash-es'

const route = useRoute()
const router = useRouter()
const element = ref(null)

const prevId = ref(null)
const nextId = ref(null)

const hasQueryParams = computed(() => {
  return route?.query['lightbox-image-id'] || route?.query['lightbox-video-id']
})

const setPrevNextId = () => {
  const slides = document.documentElement.querySelectorAll('figure[data-lightbox-image], [data-lightbox-video-id]')
  const currentId = Array.from(slides)?.findIndex(element => {
    const id = route?.query['lightbox-image-id'] || route?.query['lightbox-video-id']
    const elementId = element.hasAttribute('data-lightbox-image') ? element.dataset.uploadId : element.dataset.lightboxVideoId
    return elementId === id
  })

  if (currentId > 0) {
    const prevSlideType = slides[currentId - 1].hasAttribute('data-lightbox-image') ? 'lightbox-image-id' : 'lightbox-video-id'
    const prevSlideId = slides[currentId - 1].hasAttribute('data-lightbox-image') ? slides[currentId - 1].dataset.uploadId : slides[currentId - 1].dataset.lightboxVideoId
    prevId.value = { [prevSlideType]: prevSlideId }
  } else {
    prevId.value = null
  }

  if (currentId < (slides?.length - 1)) {
    const nextSlideType = slides[currentId + 1].hasAttribute('data-lightbox-image') ? 'lightbox-image-id' : 'lightbox-video-id'
    const nextSlideId = slides[currentId + 1].hasAttribute('data-lightbox-image') ? slides[currentId + 1].dataset.uploadId : slides[currentId + 1].dataset.lightboxVideoId
    nextId.value = { [nextSlideType]: nextSlideId }
  } else {
    nextId.value = null
  }
}

const getLightBoxImageIdQueryParams = (object) => {
  const objectIncludesImageId = object['lightbox-image-id']?.length > 0
  const query = objectIncludesImageId ? omit(route?.query, 'lightbox-video-id') : omit(route?.query, 'lightbox-image-id')
  const path = {
    path: route?.path,
    query: {
      ...query,
      ...object
    }
  }
  return path
}

const onKeyDownCycle = (dir) => {
  if (prevId.value && dir === 'prev') {
    router.push(getLightBoxImageIdQueryParams(prevId.value))
  }
  if (nextId.value && dir === 'next') {
    router.push(getLightBoxImageIdQueryParams(nextId.value))
  }
}

onMounted(() => {
  setPrevNextId()
  nextTick(() => {
    element?.value?.focus()
  })
})

watch(hasQueryParams, () => {
  setPrevNextId()
  nextTick(() => {
    element?.value?.focus()
  })
})
</script>

<style module>
.root {
  height: 100%;
  overflow: hidden;

  position: relative;

  color: var(--color--white);
}

.root:focus {
  outline: none;
}

.button {
  composes: reset-button from global;

  position: absolute;
  top: 50%;

  margin-left: var(--unit--horizontal);
  margin-right: var(--unit--horizontal);
}

.button[dir='prev'] {
  transform: translateY(-50%);
  left: 0;
}

.button[dir='next'] {
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}

.buttonInner {
  composes: button from global;
}

.svg {
  composes: button-svg from global;
}
</style>

<template>
  <baseStrapiImageComponent
    :class="$style.root"
    :style="`--width-in-mm: ${entry?.widthInMm}`"
    :image="coverImage"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const coverImage = baseStrapiGetAttributes(props.entry?.coverImage?.data) || props.entry?.coverImage
</script>

<style module>
.root {
  --width--reference: 210;
  --width--max: max(var(--width--reference), var(--width-in-mm, 0));

  --width--min: max(calc((var(--width-in-mm, 0) / var(--width--reference)) * 100%), 50%);
  --width: min(var(--width--min), 100%);

  width: var(--width);

  margin: 0;
  margin-left: auto;
  margin-right: auto;

  pointer-events: none;
}

.root :global(.lazyloaded) {
}
</style>

export const getLinkItem = (item: object) => {
  if (!item) { return }
  if (item instanceof Object === false) { return }
  const data = baseStrapiGetAttributes(item)

  let firstFilledType = null
  for (const [key, value] of Object.entries(data)) {
    if (value instanceof Object && value?.data?.attributes) {
      firstFilledType = {
        uid: key,
        ...value?.data?.attributes
      }
      break
    }
  }

  return firstFilledType
}

export const getImageOrientation = (image: Object) => {
  if (!image?.attributes?.width && !image?.width) return null
  const width = image?.attributes?.width || image?.width
  const height = image?.attributes?.height || image?.height
  if (width > height) {
    return 'landscape'
  } else if (width < height) {
    return 'portrait'
  } else {
    return 'square'
  }
}

export const getCssColorString = (options: ColorObject, withDefaults = false) => {
  const backgroundColor = (options?.backgroundColor && options?.useBackgroundColor) || withDefaults
  const primaryColor = (options?.primaryColor && options?.usePrimaryColor) || withDefaults
  const secondaryColor = (options?.secondaryColor && options?.useSecondaryColor) || withDefaults

  const styleString = [
    backgroundColor ? `--color--background:${options?.backgroundColor || 'var(--color--background--default)'}` : '',
    primaryColor ? `--color--primary:${options?.primaryColor || 'var(--color--primary--default)'}` : '',
    secondaryColor ? `--color--secondary:${options?.secondaryColor || 'var(--color--secondary--default)'}` : '',
    backgroundColor ? `--color--text-decoration:${options?.backgroundColor || 'var(--color--secondary--default)'}` : '',
  ].filter(Boolean).join('; ')

  return styleString || '--color--background: var(--color--background--default); --color--primary: var(--color--primary--default); --color--secondary: var(--color--secondary--default);'
}

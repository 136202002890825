import qs from 'qs'

const fields = [
  'names',
  'collectiveName',
  'richText'
]

export const queryBiennialParticipantLinkThumbnail = (options: QueryBySlugOrIdOptions) => {
  const query = qs.stringify(
    {
      sort: ['id:asc'],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 100
      },
      fields,
      populate: {
        biennialEntries: {
          fields: ['slug']
        },
        images: {
          fields: ['url'],
          paginate: {
            page: 1
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-participant-entries?${query}`
}

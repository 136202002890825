<template>
  <span :class="$style.root">
    <span
      v-for="(num, i) in 3"
      v-html="'.'"
      :class="$style.dot"
      />
  </span>
</template>



<style lang="scss" module>
.root {
  display: inline-block;
}

.dot {
  display: inline-block;
  animation: blink 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.25s;
}

.dot:nth-child(3) {
  animation-delay: 0.5s;
}


@keyframes blink {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}
</style>

import qs from 'qs'

const fields = [
  'richText'
]

export const queryFooter = () => {
  const query = qs.stringify(
    {
      fields,
      populate: {
        links: {
          ...queryFragmentLink
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `footer?${query}`
}

<template>
  <div
    :class="$style.root"
    :data-paused-manually="pauseManually ? '' : null"
    :data-has-loaded="hasLoaded ? '' : null"
  >
    <mux-player
      theme="microvideo"
      :playback-id="video?.attributes?.playback_id"
      streamType="on-demand"

      muted
      loop
      playsinline

      :poster="poster"

      @loadeddata="hasLoaded = true"
      @click="pauseManually = !pauseManually"

      ref="element"
      :class="$style.video"
    />

    <transition name="fade">
      <CoreMuxVideoPlayButtonComponent
        v-if="pauseManually"
        @click="pauseManually = !pauseManually"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import "@mux/mux-player";
import "@mux/mux-player/themes/microvideo"

const element = ref(null)
const pauseManually = ref(false)
const hasLoaded = ref(false)

const props = defineProps({
  video: {
    type: Object
  },
  poster: {
    type: String
  }
})

// 1
const { stop } = useIntersectionObserver(
  element,
  ([{ isIntersecting }], observerElement) => {
    if (!pauseManually.value) {
      isIntersecting ? element?.value?.play() : element?.value?.pause()
    }
  },
)

// 2
watch(
  () => pauseManually.value,
  (value) => {
    if (value) {
      element?.value?.pause()
    } else {
      element?.value?.play()
    }
  },
)
</script>

<style module>
.root {
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.root:focus {
  outline: none;
}

.root:not([data-has-loaded]) {
  visibility: hidden;
}

.button {
  composes: reset-button from global;
  position: absolute;
  bottom: var(--padding--element--bottom);
  left: var(--unit--horizontal);

  composes: font-size-small from global;
  color: var(--color--secondary);
}

.root:not(:hover) .button {
  visibility: hidden;
}

.root[data-paused-manually][data-has-loaded] .button {
  visibility: visible;
}

.video {
  aspect-ratio: var(--aspect-ratio);
  width: 100%;
  height: 100%;
}

.root mux-player::part(airplay button),
.root mux-player::part(mute button),
.root mux-player::part(play button),
.root mux-player::part(fullscreen button) {
  display: none;
}
</style>

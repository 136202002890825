<template>
  <div ref="element">
    <NavigationLinkComponent
      :class="$style.root"
      :data-logo="linkItem?.title?.trim()?.toLowerCase() === 'manifesta' ? '' : null"
      :item="item"
    >
      {{ linkItem?.title }}
    </NavigationLinkComponent>
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const linkItem = getLinkItem(props.item)

const element = ref(null)
const observer = ref(null)
const config = { attributes: true, childList: true, subtree: true }

const scrollIntoView = () => {
  const nav = document.getElementById('nav')
  const navScrollWidth = nav?.scrollWidth
  const navWidth = nav?.getBoundingClientRect()?.width
  if (navScrollWidth >= navWidth) {
    const paddingLeft = element?.value?.firstChild ? parseInt(getComputedStyle(element?.value?.firstChild).paddingLeft) : 0
    const scrollLeft = nav?.scrollLeft
    const elementLeft = element?.value?.getBoundingClientRect()?.x
    const left = elementLeft + scrollLeft - paddingLeft
    nav?.scrollTo({top: 0, left: left, behavior: "smooth"})
  }
}

const callback = useDebounce((mutationList, observer) => {
  for (const mutation of mutationList) {
    if(mutation?.target?.classList?.contains("router-link-exact-active")) {
      scrollIntoView()
    }
  }
}, 25)

onMounted(() => {
  if (process.client) {
    if (!element.value) { return }
    observer.value = new MutationObserver(callback)
    observer.value.observe(element.value, config)

    if (element?.value?.firstChild?.classList?.contains("router-link-exact-active")) {
      scrollIntoView()
    }
  }
})

onBeforeUnmount(() => {
  if (!element.value) { return }
  observer.value.disconnect()
})
</script>

<style module lang="scss">
.root {
  composes: button-focus from global;

  display: block;
  padding: var(--padding--button--large);
  background-color: var(--color--background);
  position: relative;
}

.root:hover {
  text-decoration-line: underline;
}

.root[data-logo] {
  text-transform: uppercase;
  font-feature-settings: "dlig" 1;
}

.root:focus-within,
.root[data-current-route] {
  background-color: var(--color--primary);
  color: var(--color--background);
}
</style>

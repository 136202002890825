<template>
  <i18nLink
    :to="{ name: 'news-slug', params: { slug: entry?.slug } }"
    :class="$style.root"
    :style="cssColorString"
    :data-without-relation="!relations ? '' : null"
  >
    <coreDateTimeRangeComponent
      v-if="entry?.datePosted"
      :class="$style.datePosted"
      :date-start="entry?.datePosted"
    />

    <div :class="$style.title">
      {{ titleTruncated }}
    </div>

    <div
      v-if="relations"
      :class="$style.relations"
    >
      {{ relations?.trim() }}
    </div>

    <div
      v-if="coverImage?.formats"
      :class="$style.coverImage"
    >
      <baseStrapiImageComponent
        :image="coverImage"
      />
    </div>

    <CoreTextComponent
      v-if="firstParagraph"
      :string="firstParagraph"
      :class="$style.firstParagraph"
    />
  </i18nLink>
</template>

<script lang="ts" setup>
import { uniq } from 'lodash-es'

const props = defineProps({
  entry: {
    type: Object
  }
})

const biennialEntries = props.entry?.biennialEntries?.data || props.entry?.biennialEntries || []
const relations = biennialEntries?.map(i => i?.attributes?.title?.trim())?.filter(Boolean)?.join(', ') || biennialEntries?.map(i => i?.title)?.filter(Boolean)?.join(', ')
const coverImage = baseStrapiGetAttributes(props.entry?.coverImage?.data) || props.entry?.coverImage
const colors = biennialEntries?.find(i => i?.attributes?.colors)?.attributes?.colors || biennialEntries?.find(i => i?.colors)?.colors
const cssColorString = getCssColorString(colors)
const titleTruncated = baseTruncateString(props?.entry?.title, 12)
const firstParagraph = baseStrapiGetFirstParagraphFromBlocks(props?.entry?.blocks, 24)
</script>

<style module lang="scss">
.root {
  composes: reset-link from global;
  --button-focus--box-shadow--color: var(--color--primary--default);

  width: var(--max-width--thumbnail);
  max-width: calc(100vw - var(--unit--horizontal) * 2);

  display: block;

  background-color: var(--color--background);
  color: var(--color--primary);
  padding: var(--unit--vertical) var(--unit--horizontal);
}

.root[data-without-relation] {
  padding-top: 0;
  padding-bottom: 0;
}

.root:focus-visible,
.root:focus-within {
  outline: none;
}

.root:focus-within .title {
  text-decoration-line: none;
}

.title {
  text-decoration-line: none;
}

.root:hover .title {
  text-decoration-line: underline;
}

.datePosted {
  composes: font-size-small from global;
  color: var(--color--secondary);
}

.title {
  composes: font-size-medium from global;
}

.relations {
}

.coverImage {
  display: block;

  margin-top: var(--font-small--line-height);
}

.coverImage [data-orientation="landscape"] {
  --base--image--aspect-ratio: 13 / 9;
  --base--image--object-fit: cover;
}

.coverImage [data-orientation="square"] {
  --base--image--aspect-ratio: 1;
  --base--image--object-fit: cover;
}

.coverImage [data-orientation="portrait"] {
  --base--image--aspect-ratio: 13 / 9;
  --base--image--object-fit: contain;
}

.firstParagraph {
  margin-top: var(--font-small--line-height);
}
</style>

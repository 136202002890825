<template>
  <!-- 1. external link -->
  <!-- 2. hardcoded pages -->
  <!-- 3. dynamic pages -->
  <a
    v-if="link && hasUrl && label"
    :href="linkOptions?.url"
    target="_blank"
    :class="$style.root"
  >
    {{ label?.trim() }} ↗
  </a>

  <i18nLink
    v-else-if="link && hasPage && LinkToPageRouteObject"
    :to="LinkToPageRouteObject"
    :class="$style.root"
  >
    {{ label }}
  </i18nLink>

  <i18nLink
    v-else-if="link && firstLinkToCollection?.key && LinkToCollectionRouteObject "
    :to="LinkToCollectionRouteObject"
    :class="$style.root"
  >
    {{ label || attributes(firstLinkToCollection)?.title }}
  </i18nLink>
</template>

<script lang="ts" setup>
import { without } from 'lodash-es'

const props = defineProps<{
  link: object
}>()

const label = props?.link?.label

const linkOptions = without(Object.keys(props?.link), ['id', 'label'])?.sort()?.reduce((objEntries, key) => {
  objEntries[key] = props?.link[key]
  return objEntries
}, {})

const hasUrl = linkOptions?.url
const hasPage = linkOptions?.page
// NOTE this is nasty hack
const query = props?.link?.query ? JSON.parse(props?.link?.query) : {}
const LinkToPageRouteObject = {
  ...baseRouteGetObject(
    strapiGetRouteReference(linkOptions?.page)?.name,
  ),
  query: query
}

const firstLinkToCollection = baseFindValAndKeyByKeyInObject(linkOptions, 'data')
const LinkToCollectionRouteObject = computed(() => {
  if (firstLinkToCollection?.key === 'educationEntry') {
    return getBiennialEducationSlugPath(props?.link?.educationEntry?.data?.attributes?.slug, props?.link?.educationEntry?.data?.attributes?.biennialEntry?.data?.attributes?.slug)
  } else if (firstLinkToCollection?.key === 'urbanVisionEntry' && props?.link?.urbanVisionEntry?.data?.attributes?.biennialEntry?.data?.attributes?.slug) {
    return {
      name: 'biennials-slug-index-urban-vision',
      params: { slug: props?.link?.urbanVisionEntry?.data?.attributes?.biennialEntry?.data?.attributes?.slug },
    }
  } else {
    return baseRouteGetObject(
      strapiGetRouteReference(firstLinkToCollection?.key)?.name,
      baseStrapiGetAttributes(firstLinkToCollection)?.slug
    )
  }
})


const attributes = baseStrapiGetAttributes

</script>

<style module>
.root {
  composes: reset-link from global;

  /* text-decoration-line: underline; */

  cursor: pointer;
}

.root:focus {
  outline: none;
  text-decoration-line: underline;
}

.root:hover {
  text-decoration-line: underline;
}
</style>

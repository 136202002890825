import qs from 'qs'
import blocks from '~/queries/shared/blocks'

const fields = [
  'slug'
]

export const queryBiennialThumbnailLatest = (options: QueryBySlugOrIdOptions) => {
  const query = qs.stringify(
    {
      publicationState: 'live',
      pagination: {
        limit: 1
      },
      sort: ['editionIndex:desc'],
      fields
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-entries?${query}`
}

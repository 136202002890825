<template>
  <div
    :class="$style.root"
  >
    <i18nLink
      :to="routeObject"
      :class="$style.link"
    >
      <div :class="$style.title">
        {{ entry?.title }}
      </div>
    </i18nLink>

    <div
      v-if="relation && showRelation"
      :class="$style.relation"
    >
      {{relation?.title}}
    </div>

    <div
      v-if="firstParagraph"
      :class="$style.description"
      v-html="firstParagraph"
    />

    <i18nLink
      :to="routeObject"
      :class="$style.moreLink"
    >
      {{ $t('shared.more') }}
    </i18nLink>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

const props = defineProps({
  entry: {
    type: Object,
    required: true
  },
  showRelation: {
    type: Boolean,
    default: false
  }
})

const relation = props.entry?.biennialEntry?.data?.attributes || props.entry?.biennialEntry
const routeObject = getBiennialEducationSlugPath(props?.entry?.slug, relation?.slug)
const firstParagraph = baseStrapiGetFirstParagraphFromBlocks(props?.entry?.blocks, 40)
</script>

<style module>
.root {
}

.link {
  composes: reset-link from global;

  display: block;
}

.image {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, 9 / 6);
  --base--image--object-fit: cover;

  max-width: 10rem;
  margin-top: var(--padding--element--bottom);
}

.link:hover .title {
  text-decoration-line: none;
}

.title {
  composes: font-size-medium from global;
  text-decoration-line: underline;
}

.relation {
  margin-bottom: var(--font-small--line-height);
}

.description {
}

.moreLink {
  composes: reset-link link from global;
  margin-top: var(--padding--element--top);
  display: inline-block;
  text-decoration-line: underline;
}
</style>

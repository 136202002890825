import qs from 'qs'

const fields = [
  'title',
  'slug',

  'dateStart',
  'dateEnd',
  'hideDates',

  'editionIndex',
  'country',
  'city',
  'titleAnnotation'
]

export const queryBiennialEntriesThumbnail = (options: QueryManyOptions) => {
  const query = qs.stringify(
    {
      sort: options?.sort || ['editionIndex:desc'],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 20
      },
      fields,
      populate: {
        colors: true
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-entries?${query}`
}

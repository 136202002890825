<template>
  <div :class="$style.root">
    <div :class="$style.label">
      {{ $t('newsletter.prompt') }}
    </div>

    <div :class="$style.content">
      <div :class="$style.inputs">
        <input 
          :class="[$style.input, $style.email]" 
          placeholder="Email" 
          v-model="email" 
          type="email" 
          autocomplete="off"
          @keyup.enter="subscribe" 
        />
        <div :class="$style.inputsColumns">
          <input :class="$style.input" :placeholder="$t('newsletter.firstName')" v-model="firstName" autocomplete="off"
            @keyup.enter="subscribe" />
          <input :class="$style.input" :placeholder="$t('newsletter.lastName')" v-model="lastName" autocomplete="off"
            @keyup.enter="subscribe" />
        </div>
      </div>
      <button :class="$style.button" :disabled="!isValid ? '' : null" @click="subscribe">
        <SvgoArrow class="button-svg" />
      </button>
    </div>

    <div v-if="res?.success" :class="$style.message">
      {{ $t('newsletter.confirmation') }}
    </div>
    <div v-if="res?.signedUp" :class="$style.message">
      {{ $t('newsletter.signedUp') }}
    </div>
  </div>
</template>

<script setup>
import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
const email = ref('')
const firstName = ref('')
const lastName = ref('')

const res = ref(null)
watch(res, (val) => {
  if (val) {
    setTimeout(() => {
      res.value = null
    }, 10000)
  }
})

const isValid = computed(() => {
  return isEmail(email.value) && isLength(firstName.value, { min: 1 }) && isLength(lastName.value, { min: 1 })
})

const subscribe = async () => {
  if (!isValid.value) return

  const { data, error } = await useFetch('/api/mailchimp', { params: { email: email.value, firstName: firstName.value, lastName: lastName.value } })
  res.value = data?.value
}
</script>

<style module>
.root {
  composes: font-size-small from global;
}

.content {
  display: flex;
  align-items: center;
  gap: var(--unit--spacer);
}

.label {
  margin-bottom: var(--padding--element--bottom);
  color: var(--color--secondary);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: var(--stroke--default);
  flex: 1;
}

.inputsColumns {
  display: flex;
  gap: var(--stroke--default);
}

.input {
  composes: reset-input from global;
  backdrop-filter: var(--backdrop-filter--dark);
  padding: var(--padding--element);
  width: 100%;
}

.input::placeholder {
  color: var(--color--secondary);
}

.input:focus {
  backdrop-filter: var(--backdrop-filter--light);
}

.button {
  composes: reset-button button from global;
  transform: rotateZ(180deg);
}

.button[disabled] {
  pointer-events: none;
}

.button[disabled] * {
  stroke: var(--color--secondary);
}

.message {
  margin-top: var(--padding--element--top);
}
</style>

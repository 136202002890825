<template>
  <div :class="$style.root" :style="cssColorString">
    <div :class="$style.content">
      <SharedEducationThumbnailComponent
        :entry="hit"
        :class="$style.thumbnail"
        :showRelation="true"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  hit: Object
})

const colors = props?.hit?.biennialEntry?.colors
const cssColorString = getCssColorString(colors)
</script>

<style module>
.root {
}

.content {
  /* border-left: var(--stroke--default) solid var(--color--background);
  padding: var(--padding--element);
  margin-left: calc(var(--padding--element--horizontal) * -1); */
}

.thumbnail {
  --color--primary: var(--color--primary--default);
  --color--secondary: var(--color--secondary--default);
}

.thumbnail * {
  text-decoration-color: var(--color--text-decoration);
}
</style>

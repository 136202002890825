<template>
  <div
    :class="$style.root"
    :style="`--scale: ${props?.scale};`"
  >
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  scale: {
    type: Number,
    default: 1
  }
})
</script>

<style module>
.root[data-orientation="landscape"] {
  --base--image--aspect-ratio: 9 / 6;
  --base--image--object-fit: cover;

  width: calc((var(--container--width) - (var(--unit--horizontal) * 2)) * var(--scale, 1));
  max-width: calc(100vw - var(--stroke--thick) * 3);
}

.root[data-current][data-orientation="landscape"] {
  width: calc(var(--container--width--large) * var(--scale, 1));
}

.root[data-orientation="square"] {
  --base--image--aspect-ratio: 9 / 6;
  --base--image--object-fit: cover;

  width: calc((var(--container--width) - (var(--unit--horizontal) * 2)) * var(--scale, 1));
  max-width: calc(100vw - var(--stroke--thick) * 3);
}

.root[data-current][data-orientation="square"] {
  width: calc(var(--container--width--large) * var(--scale, 1));
}

.root[data-orientation="portrait"] {
  --base--image--aspect-ratio: 2 / 3;
  --base--image--object-fit: cover;

  width: calc(30rem * var(--scale, 1));
  max-width: calc(100vw - var(--stroke--thick) * 3);
}

.root[data-current][data-orientation="portrait"] {
  width: calc(38rem * var(--scale, 1));
}
</style>

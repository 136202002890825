<template>
  <div id="app" :class="$style.root">
    <SharedGtagConsentComponent />

    <transition name="mask">
      <NavigationMenuComponent
        v-show="menuVisible"
        :isOpen="menuVisible"
        @closeSelf="menuVisible = false"
      />
    </transition>

    <NavigationMenuButtonComponent
      :data-menu-open="menuVisible ? '' : null"
      @click="menuVisible = !menuVisible"
    />
    <NavigationSiteNavComponent
      @onToggleSearch="searchVisible = !searchVisible"
    />

    <SearchComponent
      v-show="searchVisible"
      :isOpen="searchVisible"
      @closeSelf="searchVisible = false"
    />

    <NavigationLoadingIndicatorComponent />

    <main :class="$style.page">
      <NuxtPage />
    </main>

    <SharedFooterComponent />

    <CoreLightBoxComponent />
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp
const runtimeConfig = useRuntimeConfig()

const routeName = computed(() => nuxtApp.$getRouteBaseName())

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${$i18n.t('siteTitle')}` : `${$i18n.t('siteTitle')}`
  },
  script: [
    {
      src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
    },
    {
      hid: "INITIATE_RELIC",
      src: "/relic.js",
      defer: true,
      type: "text/javascript"
    },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '18', href: `${runtimeConfig?.public?.baseUrl}/favicon/dark/apple-touch-icon.png`, media: "(prefers-color-scheme: dark)" },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${runtimeConfig?.public?.baseUrl}/favicon/dark/favicon-32x32.png`, media: "(prefers-color-scheme: dark)" },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${runtimeConfig?.public?.baseUrl}/favicon/dark/favicon-16x16.png`, media: "(prefers-color-scheme: dark)" },
    { rel: 'apple-touch-icon', sizes: '18', href: `${runtimeConfig?.public?.baseUrl}/favicon/light/apple-touch-icon.png`, media: "(prefers-color-scheme: light)" },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${runtimeConfig?.public?.baseUrl}/favicon/light/favicon-32x32.png`, media: "(prefers-color-scheme: light)" },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${runtimeConfig?.public?.baseUrl}/favicon/light/favicon-16x16.png`, media: "(prefers-color-scheme: light)" },
  ],
  htmlAttrs: {
    'data-route-name': routeName
  }
})

const menuVisible = ref(false)
const searchVisible = ref(false)

const router = useRoute()
const path = computed(() => router?.path)
watch(path, () => {
  menuVisible.value = false
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;

  min-height: var(--100svh);
}

.page {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.page>* {
  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>

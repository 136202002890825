<template>
  <button
    :class="$style.root"
  >
    <SvgoTriangle :class="$style.svg" />
  </button>
</template>

<style module>
.root {
  composes: reset-button from global;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transition: transform .25s ease;
  transform: translate(-50%, -50%);
}

.root:hover {
  transform: scale(1.15) translate(-50%, -50%);
}

.svg {
  composes: button-svg from global;
  width: calc(var(--size--button) * 1.5);
  aspect-ratio: 1;
}

.svg * {
  stroke: none;
  fill: var(--color--primary);
}
</style>

<template>
  <div
    :class="$style.root"
    ref="element"
    @keyup.esc="$emit('closeSelf')"
    tabindex="0"
  >
    <Transition name="mask">
      <div
        :class="$style.content"
        ref="content"
        v-if="isOpen"
      >
        <div :class="$style.top">
          <input
            v-model="queryInput"
            :class="$style.input"
            :placeholder="$t('search.placeholder')"
            ref="inputElement"
            tabindex="0"
            :disabled="isPending ? '' : null"
          >

          <button
            :class="$style.button"
            @click="$emit('closeSelf')"
          >
            <SvgoCross class="button-svg" />
          </button>
        </div>

        <SearchHitsComponent
          :queryString="query"
          @pending="isPending = $event"
        />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { refDebounced } from '@vueuse/core'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const inputElement = ref(null)
const queryInput = ref(null)
const isPending = ref(false)
const query = refDebounced(queryInput, 750)
const nuxtApp = useNuxtApp()
const content = ref(null)
const emit = defineEmits(['closeSelf'])
const route = useRoute()
const path = computed(() => route?.path)

onClickOutside(content, (event) => emit('closeSelf'))

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      inputElement.value?.focus()
      document.documentElement.setAttribute('data-overflow-hidden', '')
    }, 50)
  } else {
    queryInput.value = null
    query.value = null
    document.documentElement.removeAttribute('data-overflow-hidden', '')
  }
})

watch(path, () => {
  emit('closeSelf')
  queryInput.value = null
  query.value = null
})
</script>

<style module>
.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index--search);

  height: var(--100dvh);
  width: 100vw;
  overflow: scroll;

  backdrop-filter: var(--backdrop-filter--light);
}

.content {
  --color--background: var(--color--background--default);
  --color--primary: var(--color--primary--default);
  --color--secondary: var(--color--secondary--default);

  background-color: var(--color--background);
  color: var(--color--primary);
}

.top {
  composes: container from global;

  background-color: var(--color--background);
  color: var(--color--primary);

  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: .25ch;
  padding: calc( var(--unit--vertical) + var(--padding--button--large--top)) var(--unit--horizontal);
}

.button {
  composes: reset-button button from global;
}

.input {
  composes: reset-input font-size-large from global;

  padding-bottom: var(--stroke--default);
  border-bottom: var(--stroke--default) solid;

  /* min-width: 20rem; */
  max-width: calc(100% - var(--size--button) - .25ch);
  flex: 1;

  text-overflow: ellipsis;
}

.input[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.input::placeholder {
  color: var(--color--primary);
}

.linkToParent:empty {
  display: none;
}

</style>

// NOTE should make sure this only runs when useAsyncData is not loading
// NOTE this is a bigger refactor
export const paginatedQuery = async (query: Function, id: string, queryKey: string, queryId: any, querySecondaryKey: string, querySecondaryId: any) => {
  // 1
  const entries = ref({
    data: [],
    pagination: null
  })

  const getDataFromQuery = (data: any) => {
    entries.value = {
      data: entries.value.data.concat(data?.data)?.filter(Boolean),
      pagination: data?.meta?.pagination
    }
  }

  const entriesPaginationPage = ref(1)
  const incrementPage = () => {
    entriesPaginationPage.value++
  }

  const shouldAutoLoad = computed(() => {
    return entries?.value?.pagination?.page < entries?.value?.pagination?.pageCount
  })

  // 2
  const queryIdWatcher = ref(queryId || null)
  const querySecondaryIdWatcher = ref(querySecondaryId || null)

  watch([queryIdWatcher, querySecondaryIdWatcher], () => {
    entries.value = {
      data: [],
      pagination: null
    }
    entriesPaginationPage.value = 1
  })

  const queryObject = computed(() => {
    return queryKey && queryId?.value ? { [queryKey]: queryId?.value } : {}
  })

  const querySecondaryObject = computed(() => {
    return querySecondaryKey && querySecondaryId?.value ? { [querySecondaryKey]: querySecondaryId?.value } : {}
  })

  // 3
  const { data: entriesData, error: entriesError, pending } = await useAsyncData(id, () =>
    $fetch('/api/strapi-rest',
      { query: { path: query({ ...queryObject.value, ...querySecondaryObject.value, page: entriesPaginationPage.value }) } }
    ),
    { watch: [entriesPaginationPage, queryIdWatcher, querySecondaryIdWatcher] })

  watch(entriesData, (value) => {
    getDataFromQuery(value)
  }, {
    immediate: true
  })

  watch(entries, (value) => {
    if (shouldAutoLoad.value) {
      incrementPage()
    }
  }, {
    immediate: false
  })

  // NOTE this is a hack to make sure the first page is loaded
  setTimeout(() => {
    if (shouldAutoLoad.value) {
      incrementPage()
    }
  }, 1)

  return entries
}

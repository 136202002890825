<template>
  <menu
    :class="$style.root"
    @keyup.esc="$emit('closeSelf')"
    tabindex="0"
    ref="element"
  >
    <div :class="$style.content">
      <ul :class="$style.list">
        <li
          v-for="item in items"
          :key="item?.id"
          :class="$style.item"
        >
          <NavigationMenuItemComponent :item="item" />
        </li>
      </ul>

      <ul :class="$style.list">
        <li
          v-for="item in entries?.data"
          :key="item?.id"
        >
          <i18nLink
            :to="{name: 'biennials-slug-index-about', params: {slug: item?.attributes?.slug}}"
            :class="$style.link"
            :data-active="item?.attributes?.slug === $route?.params?.slug ? '' : null"
          >
            {{item?.attributes?.title}}
          </i18nLink>
        </li>
      </ul>

      <SharedNewsletterComponent />
    </div>
  </menu>
</template>

<script setup>
const element = ref(null)
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryMenusMenu() } }
  )
})

const paginationQuery = queryBiennialEntriesThumbnail
const entries = ref(await paginatedQuery(paginationQuery, 'menu-biennials-thumbnails'))

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      element.value?.focus()
      document.documentElement.setAttribute('data-overflow-hidden', '')
    }, 50)
  } else {
    document.documentElement.removeAttribute('data-overflow-hidden', '')
  }
})

const page = baseStrapiGetAttributes(data)
const items = page?.items?.data

</script>

<style module lang="scss">
.root {
  --color--background: var(--color--background--default);
  --color--primary: var(--color--primary--default);
  --color--secondary: var(--color--secondary--default);

  position: fixed;
  z-index: var(--z-index--menu);

  margin: 0;
  padding: 0;

  top: 0;
  left: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--color--background);
  color: var(--color--primary);

  display: flex;
  flex-direction: column;

  overflow: scroll;

  @media (max-width: $breakpoint-s) {
    justify-content: flex-start;
  }
}

.root:focus {
  outline: none;
}

.content {
  composes: container from global;
  padding-top: var(--unit--vertical--large);
  padding-bottom: var(--unit--vertical--large);

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical--large);

  margin: auto;
}

.list {
  composes: reset-list from global;

  columns: 2;
  column-gap: calc(var(--unit--horizontal) * 2);

  @media (max-width: $breakpoint-s) {
    columns: 1;
  }
}

.link {
  composes: reset-link from global;
}

.link:focus-within,
.link:hover,
.link[data-active] {
  text-decoration-line: underline;
}
</style>

<template>
  <div :class="$style.root">
    <component
      :is="renderLink === true ? i18nLink : 'div'"
      :to="routeObject"
      :class="$style.link"
      :style="colorTextDecoration"
    >
      <span :class="$style.title">{{ title }}</span>
      <span :class="$style.relation" v-if="biennialEntry?.title">{{ biennialEntry?.title }}</span>
    </component>
  </div>
</template>

<script setup>
import i18nLink from '~/components/i18n/link.vue'

const props = defineProps({
  hit: Object
})

const title = computed(() => formatBiennialParticipantHeadingObject(props?.hit)?.title)

const biennialEntry = computed(() => {
  return props?.hit?.biennialEntries?.length ? props?.hit?.biennialEntries[0] : null
})

const slug = computed(() => biennialEntry?.value?.slug)

const renderLink = computed(() => {
  return slug?.value && (props?.hit?.richText !== null) || slug?.value && props?.hit?.images?.length > 0
})

const routeObject = computed(() => {
  return renderLink.value ? getBiennialParticipantIdPath(props?.hit?.id, slug.value) : {}
})

const colors = biennialEntry?.value?.colors
const colorTextDecoration = colors?.backgroundColor && colors?.useBackgroundColor ? `--color--text-decoration: ${colors?.backgroundColor};` : ''
</script>

<style module>
.root {
  break-inside: avoid;
}

.root * {
  text-decoration-color: var(--color--text-decoration);
}

.link {
  composes: reset-link from global;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.link[href] .title {
  text-decoration-line: underline;
}

.link:hover .title {
  text-decoration-line: none;
}

.relation {
  color: var(--color--secondary--default)
}
</style>


export const getSearchLabel = (indexUid: string) => {
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp

  if (!indexUid) {
    return null
  }
  switch (indexUid) {
    case 'biennial-entry':
      return $i18n.t('pages.biennials.label')
    case 'education-entry':
      return $i18n.t('pages.education.label')
    case 'news-entry':
      return $i18n.t('pages.news.label')
    case 'publication-entry':
      return $i18n.t('pages.publications.label')
    case 'urban-vision-entry':
      return $i18n.t('pages.urbanVision.label')
    case 'biennial-participant-entry':
      return $i18n.t('pages.biennialsSlugParticipants.label')
  }
}

<template>
  <div
    v-if="video"
    :key="id"
    :class="$style.root"
  >
    <CoreMuxVideoComponent
      :video="video"
      :class="$style.video"
      :includeControls="true"
      :includeAspectRatio="false"
      :includeZoomButton="false"
    />

    <div
      :class="$style.caption"
      v-if="video?.attributes?.caption"
    >
      {{ caption }}
    </div>
  </div>
  <CoreLightBoxErrorComponent v-else>
    <template #buttonToClose>
      <slot name="buttonToClose" />
    </template>
  </CoreLightBoxErrorComponent>
</template>

<script setup>
const route = useRoute()

const id = computed(() => route?.query['lightbox-video-id'] || 1000000)

const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryMuxAssetById(id?.value) } })
}, { watch: [id] })

const video = computed(() => data?.value?.items?.length ? { attributes: data?.value?.items[0] } : null)

const caption = computed(() => {
  if (!video?.value?.attributes?.caption) { return null }
  return video?.value?.attributes?.caption[video?.value?.attributes?.caption?.length - 1] === '.' ? video?.value?.attributes?.caption : `${video?.value?.attributes?.caption?.trim()}.`
})
</script>

<style module>
.root {
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  position: relative;

  color: var(--color--white);
}

.root:focus {
  outline: none;
}

.video {
  flex: 1;
  overflow: hidden;
  user-select: none;
}

.video:focus {
  outline: none;
}

.video mux-player {
  height: 100%;
}

.caption {
  composes: font-size-default from global;
  padding: var(--unit--vertical) var(--unit--horizontal);
  text-align: center;
}
</style>

<template>
  <div :class="$style.root">
    <ul
      v-if="resultsSorted?.length"
      :class="$style.results"
    >
      <li
        v-for="(searchResult, index) in resultsSorted" :key="searchResult?.indexUid"
        :class="$style.hitGroup"
        :data-type="searchResult?.indexUid"
      >
        <SearchHitsGroupLabelComponent
          :indexUid="searchResult?.indexUid"
        />
        <ul
          :class="[
                    $style.group,
                    searchResult?.indexUid?.endsWith('-page') || searchResult?.indexUid === 'page-entry' ? $style.groupDefault : null,
                    searchResult?.indexUid === 'news-entry'? $style.groupNews : null,
                    searchResult?.indexUid === 'publication-entry' ? $style.groupPublications : null,
                    searchResult?.indexUid === 'education-entry' || searchResult?.indexUid === 'urban-vision-entry' ? $style.groupList : null,
                    searchResult?.indexUid === 'biennial-entry' ? $style.groupBiennials : null,
                    searchResult?.indexUid === 'biennial-participant-entry' ? $style.groupColumns : null,
                  ]"
        >
          <li
            v-for="(hit, index) in searchResult?.hits"
            :key="`${hit?._meilisearch_id}--${queryString}`"
          >
            <SearchHitsHitComponent
              :hit="hit"
              :uid="searchResult?.indexUid"
            />
          </li>
        </ul>
      </li>
    </ul>

    <div
      v-else-if="query && !resultsSorted?.length && !pending"
      :class="$style.notification"
      v-html="$t('search.noResults', { query: query })"
    />

    <div
      v-else-if="query && pending"
      :class="$style.notification"
    >
      Loading <CoreLoadingComponent />
    </div>

  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['pending'])

const props = defineProps({
  queryString: {
    type: String,
    default: false
  }
})

const query = computed(() => props.queryString?.length ? props.queryString : undefined)

const dataIfQuery = ref(null)
const results = computed(() => dataIfQuery?.value?.results)
const resultsSorted = computed(() => {
  const resultsWithResults = results?.value?.filter((result: object) => result.estimatedTotalHits !== 0)
  return useOrderBy(resultsWithResults, [(i) => i?.indexUid?.endsWith('-page') || i?.indexUid === 'page-entry' , (i) => getSearchLabel(i?.indexUid), (i) => i?.hits[0]?.title], ['desc', 'asc', 'asc'])
})

const { data, pending } = await useAsyncData(() => {
  return $fetch('/api/meilisearch', { query: { query: query?.value }})
}, { watch: [query] })

watch(data, () => {
  query?.value ? dataIfQuery.value = data?.value : dataIfQuery.value = null
}, { immediate: true })
</script>

<style module>
.root {
}

.results {
  composes: reset-list font-size-small from global;
  padding-bottom: calc(var(--font-default--line-height) * 2);
}

.notification {
  composes: font-size-medium container from global;
  padding-bottom: calc( var(--unit--vertical) + var(--padding--button--large--top));
  color: var(--color--secondary);
  text-align: center;
}

.notification mark {
  background-color: transparent;
  color: var(--color--primary);
}

.group {
  composes: reset-list from global;
}

.groupDefault {
  composes: container from global;
}

.groupPublications {
  composes: container-publications-page from global;
}

.groupNews {
  composes: container-news-page from global;
}

.groupBiennials {
  composes: container-index-page from global;
}

.groupList {
  composes: container from global;
  display: flex;
  flex-direction: column;
  gap: calc(var(--font-default--line-height) * 2);
}

.groupColumns {
  composes: container from global;
  columns: 2;
}
</style>

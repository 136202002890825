import { forEach } from "lodash-es"
import { createRegExp, exactly } from 'magic-regexp'

export default defineNuxtPlugin(async () => {
  const localePath = useLocalePath()

  // 0
  const entries = ref({
    data: [],
    pagination: null
  })

  const setEntries = (data: any) => {
    entries.value = {
      data: entries.value.data.concat(data?.data)?.filter(i => i?.id),
      pagination: data?.meta?.pagination
    }
  }

  // 1
  const page = ref(1)
  const { data } = await useAsyncData(() =>
    $fetch('/api/strapi-rest',
      { query: { path: queryBiennialParticipantLinkThumbnail({page: page?.value}) } }
    ),
    { watch: [page] }
  )

  setEntries(data?.value)

  const shouldAutoLoad = computed(() => {
    return entries?.value?.pagination?.page <= entries?.value?.pagination?.pageCount
  })

  watch(data, (value) => {
    setEntries(value)

    if (shouldAutoLoad.value) {
      page.value++
    }
  })

  setTimeout(() => {
    if (shouldAutoLoad.value) {
      page.value++
    }
  }, 150)

  // 3
  const nameObjectArray = computed(() => {
    return entries?.value?.data.map((entry: any) => {
      const pageEmpty = (entry?.attributes?.images?.data?.length > 0 || entry?.attributes?.richText?.length > 0) !== true
      if (pageEmpty) return null
      const biennialEntries = entry?.attributes?.biennialEntries?.data?.map((i) => i?.attributes?.slug)?.filter(Boolean)
      return {
        id: entry?.id,
        name: entry?.attributes?.collectiveName?.trim() || entry?.attributes?.names?.trim(),
        biennialEntry: biennialEntries?.length ? biennialEntries[0] : null
      }
    })?.filter(Boolean)
  })

  const onAllEntriesLoaded = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (entries?.value?.pagination?.page >= entries?.value?.pagination?.pageCount) {
          clearInterval(interval)
          resolve()
        }
      }, 50)
    })
  }

  const replaceMatchedNames = async (text: string) => {
    let rawText = text

    await onAllEntriesLoaded()

    forEach(nameObjectArray.value, (nameObject) => {
      // NOTE this is temp fix: wait for resolve https://github.com/nuxt/nuxt/issues/23232
      const path = localePath(getBiennialParticipantIdPath(nameObject?.id, nameObject?.biennialEntry))
      const regex = createRegExp(exactly(nameObject?.name).notBefore('</a>'), ['g'])
      rawText = rawText?.replace(new RegExp(regex), `<a href='${path}'>${nameObject?.name}</a>`);
    })
    return rawText
  }

  return {
    provide: {
      replaceMatchedNames: (text: string) => replaceMatchedNames(text)
    }
  }
})

<template>
  <LayoutAspectRatioComponent
    :class="$style.root"
    :data-orientation="entry?.image?.orientation"
    :data-current="isCurrent ? '' : null"
    :scale="scale"
    :style="colorTextDecoration"
  >
    <I18nLink
      :to="{name: 'biennials-slug-index-about', params: { slug: entry?.slug }}"
      :class="$style.link"
    >
      <BaseStrapiImageComponent
        v-if="image?.formats"
        :image="image"
        :class="$style.image"
      />

      <div :class="$style.information">
        <div :class="$style.title">
          <span>{{entry?.title?.trim()}}</span>{{ entry?.titleAnnotation ? `, ${entry?.titleAnnotation?.trim()?.toLowerCase()}` : null }}
        </div>
        <CoreDateTimeRangeComponent
          v-if="!entry?.hideDates"
          :date-start="entry?.dateStart"
          :date-end="entry?.dateEnd"
          :class="$style.dates"
        />
        <div
          v-if="props?.entry?.image?.richText"
          v-html="props?.entry?.image?.richText"
          :class="$style.richText"
        />
      </div>
    </i18nLink>
  </LayoutAspectRatioComponent>
</template>

<script setup>
const props = defineProps({
  entry: Object,
  scale: {
    type: Number,
    default: 1
  },
  isCurrent: {
    type: Boolean,
    default: false
  }
})

const image = props?.entry?.image?.attributes || props?.entry?.image

const colors = props?.entry?.colors
const colorTextDecoration = colors?.backgroundColor && colors?.useBackgroundColor ? `--color--text-decoration: ${colors?.backgroundColor};` : ''
</script>

<style module>
.root {
}

.root * {
  text-decoration-color: var(--color--text-decoration);
}

.link {
  composes: reset-link from global;
}

.link:focus-within {
  outline: none;
}

.link:focus-within .image {
  outline: var(--outline--focus);
}
.link:hover .title span,
.link:focus-within .title span {
  text-decoration-line: none;
}

.information {
  max-width: calc(var(--container--width) - (var(--unit--horizontal) * 2));
  margin-left: auto;
  margin-right: auto;

  padding-left: var(--unit--horizontal);
  padding-right: var(--unit--horizontal);

  composes: font-size-medium from global;
  margin-top: var(--unit--vertical);
}

.title span {
  text-decoration-line: underline;
}

.dates {
  color: var(--color--secondary);
  display: inline-block;
}

.richText {
  composes: font-size-small from global;
  margin-top: calc(var(--padding--element--top) - var(--font-medium--compensation--lh));
  color: var(--color--secondary);
}
</style>

import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    indexPageLightboxFixed: true,
    indexLogoLightboxSticky: true,
    prevRoute: {}
  }),
  actions: {
    setUserActionHasClosedIndexPageLightbox () {
      this.indexPageLightboxFixed = false
    },
    setUserActionHasToggledIndexPageLogo () {
      this.indexLogoLightboxSticky = false
    },
    setPrevRoute (route: object) {
      this.prevRoute = {
        name: route?.name,
        path: route?.path
      }
    }
  }
})

import qs from 'qs'

export const queryMuxAssetById = (id: number) => {
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: id
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `mux-video-uploader/mux-asset/?${query}`
}

<template>
    <template v-if="uid === 'biennial-entry'">
      <SearchHitsHitBiennialComponent
        :hit="hit"
        :key="hit?.slug"
      />
    </template>
    <template v-else-if="uid === 'education-entry'">
      <SearchHitsHitEducationComponent
        :hit="hit"
        :key="hit?.slug"
      />
    </template>
    <template v-else-if="uid === 'news-entry'">
      <SearchHitsHitNewsComponent
        :hit="hit"
        :key="hit?.slug"
      />
    </template>
    <template v-else-if="uid === 'publication-entry'">
      <SearchHitsHitPublicationComponent
        :hit="hit"
        :key="hit?.slug"
      />
    </template>
    <template v-else-if="uid === 'urban-vision-entry'">
      <SearchHitsHitUrbanVisionComponent
        :hit="hit"
        :key="hit?.slug"
      />
    </template>
    <template v-else-if="uid === 'biennial-participant-entry'">
      <SearchHitsHitParticipantComponent
        :hit="hit"
        :key="hit?.slug"
      />
    </template>
    <template v-else>
      <i18nLink
        :to="routeObject"
        :class="$style.link"
        :key="hit?.slug"
      >
        <span v-html="localizedData?.title" />
      </i18nLink>
    </template>
</template>

<script setup>
  const props = defineProps({
    hit: Object,
    uid: String
  })

  const hitData = computed(() => {
    return props?.hit?._formatted || props?.hit
  })

  const localizedData = hitData?.localizations?.find(i => i?.locale === baseI18nGetActiveIso() ) || hitData

  const routeObject = computed(() => {
    return baseRouteGetObject(
      strapiGetRouteReference(props?.uid)?.name,
      props?.hit?.slug
    )
  })
</script>

<style module>
.link {
  composes: reset-link font-size-medium from global;
  text-decoration-line: underline;
}

.link:hover {
  text-decoration-line: none;
}

.link mark {
  color: var(--color--primary);
  background-color: var(--color--background);
}
</style>

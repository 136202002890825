<template>
  <div>
    <SharedBiennialThumbnailComponent
      :entry="entry"
      :scale="0.65"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  hit: Object,
})

const firstTextBlock = baseStrapiGetFirstParagraphFromBlocks(props?.hit?.biennialConcept?.blocks, 16)
const images = useFlattenDeep(
  props?.hit?.biennialConcept?.blocks?.map((block) => {
    return block?.files?.map((image) => {
      return image
    })
  })
)?.filter(image => image?.id)
const image = useSample(images) || props?.hit
const entry = {
  ...props?.hit,
  image: {
    ...image,
    orientation: getImageOrientation(image),
    richText: baseTruncateString(firstTextBlock, 28)
  }
}
</script>

<style module>
.root {
  composes: reset-link font-size-large from global;
  display: block;
  margin: auto;
  text-align: center;
}

.root:not(:global(.router-link-active)):hover :global([class*='heading']) {
  text-decoration-line: underline;
}
</style>

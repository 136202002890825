<template>
  <div :class="$style.root">
    <div>
      {{ $t('lightbox.error') }}
      <slot name="buttonToClose" />
    </div>
  </div>
</template>

<style module>
.root {
  composes: font-size-large from global;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--unit--vertical) var(--unit--horizontal);

  height: 100%;
  color: var(--color--background);
}
</style>

<template>
  <transition name="fade">
    <div
      ref="element"
      :class="$style.root"
      tabindex="0"
      @keyup.esc="closeLightBox()"
      v-if="hasQueryParams"
    >
      <div :class="$style.content">
        <div
          v-if="documenTitle"
          :class="$style.title"
        >
          {{ documenTitle }}
        </div>

        <button
          :class="$style.closeButton"
          @click="closeLightBox"
        >
          <svgo-cross :class="$style.svg" />
        </button>

        <CoreLightBoxContentComponent>
          <CoreLightBoxImageComponent v-if="hasLightboxImageId">
            <template #buttonToClose>
              <button
                :class="$style.closeButtonInline"
                @click="closeLightBox"
              >
                {{ $t('shared.close') }}
              </button>
            </template>
          </CoreLightBoxImageComponent>
           <CoreLightBoxVideoComponent v-if="hasLightboxVideoId">
            <template #buttonToClose>
              <button
                :class="$style.closeButtonInline"
                @click="closeLightBox"
              >
                {{ $t('shared.close') }}
              </button>
            </template>
          </CoreLightBoxVideoComponent>
        </CoreLightBoxContentComponent>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { omit } from 'lodash-es'

const route = useRoute()
const router = useRouter()

const element = ref(null)
const documenTitle = ref(null)

const hasQueryParams = computed(() => {
  return route?.query['lightbox-image-id'] || route?.query['lightbox-video-id']
})

const hasLightboxImageId = computed(() => {
  return route?.query['lightbox-image-id']
})

const hasLightboxVideoId = computed(() => {
  return route?.query['lightbox-video-id']
})

const closeLightBox = () => {
  const queryParams = omit(route?.query || {}, ['lightbox-image-id', 'lightbox-video-id'])
  router.push({ query: queryParams })
}

const setDocumentTitle = () => {
  const title = document.title?.replace(' - Manifesta', '')
  documenTitle.value = process.client && title ? baseTruncateString(title, 4) : null
}

watch(hasQueryParams, (value) => {
  if (value) {
    document.documentElement.setAttribute('data-overflow-hidden', '')
    nextTick(() => {
      element?.value?.focus()
    })
  } else {
    document.documentElement.removeAttribute('data-overflow-hidden')
  }

  setDocumentTitle()
})

onMounted(() => {
  if (hasQueryParams.value) {
    document.documentElement.setAttribute('data-overflow-hidden', '')
    nextTick(() => {
      element?.value?.focus()
      setDocumentTitle()
    })
  }
})
</script>

<style module>
.root {
  --color--background: var(--color--background--default);
  --color--primary: var(--color--primary--default);
  --color--secondary: var(--color--secondary--default);

  position: fixed;
  z-index: var(--z-index--light-box);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--color--black);
  display: flex;
}

.root:focus {
  outline: none;
}

.content {
  width: 100vw;
  height: var(--100dvh);

  position: relative;
}

.title {
  position: absolute;
  z-index: 1;
  top: var(--unit--vertical);
  left: var(--unit--horizontal);
  color: var(--color--white);

  max-width: calc(100% - var(--unit--horizontal) * 3 - var(--size--button));

  composes: font-size-large from global;
  padding: var(--padding--button--large);
  backdrop-filter: var(--backdrop-filter--dark);
}

.closeButton {
  composes: reset-button button from global;

  position: absolute;
  z-index: 1;
  top: var(--padding--button--large--top);
  right: var(--unit--horizontal);
}

.svg {
  composes: button-svg from global;
}

.closeButtonInline {
  composes: reset-button from global;
  text-decoration-line: underline;
}
</style>

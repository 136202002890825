<template>
  <div
    :class="$style.root"
    v-html="text"
    :key="text"
  />
</template>

<script setup>
const nuxtApp = useNuxtApp()

const props = defineProps({
  string: {
    type: String,
    required: true
  },
  forceTargetBlank: {
    type: Boolean,
    default: false
  }
})

const html = baseStringToHtml(props.string, props.forceTargetBlank)
const htmlWithLinksToParticipants = ref(null)

const text = computed(() => htmlWithLinksToParticipants.value || html)

onMounted(async () => {
  htmlWithLinksToParticipants.value = await nuxtApp.$replaceMatchedNames(html)
})
</script>

<style module>
  .root {}

  .root h1,
  .root h2,
  .root h3,
  .root h4,
  .root h5,
  .root h6 {
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-weight: inherit;

    margin-bottom: var(--lh, 1em);
    margin-top: calc(var(--lh, 1em) * 2);

    color: var(--color--secondary);
  }

  .root a {
    color: inherit;
    text-decoration-thickness: inherit;
  }

  .root code {
    font-family: inherit;
  }

  .root ul,
  .root ol {
    padding: 0;
    list-style-position: inside;
  }

  .root ul li::marker {
    list-style-type: disc;
    content: "• ";
  }

  .root p {
    margin-bottom: var(--lh, 1em);
    margin-top: 0;
  }

  .root p:last-of-type {
    margin-bottom: 0;
  }

  .root p:empty {
    display: none;
  }

  .root em,
  .root i {
  }

  .root u {
  }

  .root a {
    text-decoration-line: underline;
  }

  .root a:hover {
    text-decoration-line: none;
  }

  .root a:focus-within,
  .root a:focus {
    outline: none;
    text-decoration-line: none;
  }

  .root b,
  .root strong {
  }

  .root sup,
  .root sub {
    font-size: 70%;
    line-height: 0;
  }

  .root blockquote {
    color: var(--color--secondary);
    text-align: center;

    font-size: calc(var(--font-default--font-size));
    line-height: calc(var(--font-default--line-height));
    --fs: var(--font-default--font-size);
    --lh: var(--font-default--line-height);

    margin: 0;
    margin-bottom: var(--font-small--line-height);
    padding: 0 var(--unit--horizontal);
  }
</style>

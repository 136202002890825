import { camelCase } from 'lodash-es'
export const strapiGetRouteReference = (uid: string) => {
  const uidString = camelCase(uid)
  switch (uidString) {
    // 1
    case 'biennialPage':
      return {
        name: 'biennials'
      }
    case 'biennialEntry':
    case 'biennialConcept':
      return {
        name: 'biennials-slug-index-about',
        slug: true
      }
    case 'biennialParticipantEntry':
      return {
        name: 'participants-preview-slug',
        slug: true
      }

    // 2
    case 'educationPage':
      return {
        name: 'index-education',
        slug: false
      }
    //
    case 'educationEntry':
      return {
        name: 'biennial-slug-education-slug',
        slug: true
      }

    // 4
    case 'homePage':
      return {
        name: 'index'
      }

    // 5
    case 'newsPage':
      return {
        name: 'news',
        slug: false
      }
    case 'newsEntry':
      return {
        name: 'news-slug',
        slug: true
      }

    // 6
    case 'pageEntry':
      return {
        name: 'index-slug',
        slug: true
      }

    // 7
    case 'pressPage':
      return {
        name: 'index-press',
        slug: false
      }
    case 'pressEntry':
      return {
        name: 'press-slug',
        slug: true
      }

    // 7
    case 'publicationsPage':
      return {
        name: 'publications',
        slug: false
      }
    case 'publicationEntry':
      return {
        name: 'publications-slug',
        slug: true
      }

    // 8
    case 'urbanVisionPage':
      return {
        name: 'index-urban-vision',
        slug: false
      }
    case 'urbanVisionEntry':
      return {
        name: 'index-urban-vision-slug',
        slug: true
      }

    default:
      return {
        name: 'index',
        slug: false
      }
  }
}

<template>
  <button
    :class="$style.root"
    tabindex="0"
  >
    <div :class="$style.container">
      <SvgoLine :class="$style.line" />
      <SvgoLine :class="$style.line" />
      <SvgoLine :class="$style.line" />
    </div>
  </button>
</template>

<style module>
.root {
  composes: reset-button button from global;
  position: fixed;
  z-index: calc(var(--z-index--menu) + 1);
  top: var(--unit--vertical);
  right: var(--unit--horizontal);
}

.root[data-menu-open] .container {
  transform: translateY(-5%);
}

.container {
  position: relative;

  width: 50%;
  aspect-ratio: 1.35;

  pointer-events: none;
  overflow: visible;

  transition: transform .15s ease-in-out;
}

.line {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--stroke--default);

  --transition: top cubic-bezier(0.215, 0.610, 0.355, 1) .2s, transform cubic-bezier(0.215, 0.610, 0.355, 1) .2s;
}

.line {
  overflow: visible;
  width: 100%;

  margin: 0;
  padding: 0;
}

.line * {
  stroke: var(--color--primary);
  stroke-width: var(--stroke--default);
}

.line:nth-child(1) {
  top: 0;
  transform: translateY(-50%);
  transition: var(--transition);
}

.root[data-menu-open] .line:nth-child(1) {
  top: 50%;
  transform: rotate(45deg);
}

.line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.root[data-menu-open] .line:nth-child(2) {
  width: 0;
  height: 0;
  overflow: hidden;
}

.line:nth-child(3) {
  top: 100%;
  transform: translateY(-50%);
  transition: var(--transition)
}

.root[data-menu-open] .line:nth-child(3) {
  top: 50%;
  transform: rotate(-45deg);
}
</style>

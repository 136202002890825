<template>
  <div :class="$style.root" :style="cssColorString">
    <div :class="$style.content">
      <i18nLink
        :to="{ name: 'publications-slug', params: { slug: entry?.slug }, query: route?.query }"
        :class="$style.link"
      >
        <div :class="$style.heading">
          <div :class="$style.category">
            {{ category }}
          </div>
          <div :class="$style.title">
            {{ entry?.title }}
          </div>
          <div v-if="relation?.title">
            {{ relation?.title }}
          </div>
        </div>
      </i18nLink>

      <i18nLink
        v-if="coverImage"
        :to="{ name: 'publications-slug', params: { slug: entry?.slug }, query: route?.query }"
        :class="$style.imageLink"
      >
        <SharedPublicationImageComponent :entry="entry" :class="$style.image" />
      </i18nLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
import i18nLink from '~/components/i18n/link.vue'

const props = defineProps({
  entry: {
    type: Object
  }
})

const relation = props.entry?.biennialEntry?.data?.attributes || props.entry?.biennialEntry

const coverImage = baseStrapiGetAttributes(props.entry?.coverImage?.data) || props.entry?.coverImage
const file = baseStrapiGetAttributes(props.entry?.file?.data) || props.entry?.file

const fileUrl = baseStrapiGetAbsoluteFileUrl(file?.url, file?.provider)
const category = props?.entry?.publicationCategory?.data?.attributes?.title || props?.entry?.publicationCategory?.title

const colors = relation?.colors
const cssColorString = getCssColorString(colors)
</script>

<style module>
.root {
  composes: font-size-small from global;

  width: var(--max-width--thumbnail);
  max-width: 100vw;
}

.root * {
  text-decoration-color: var(--color--text-decoration);
}

.root:focus-visible,
.root:focus-within {
  outline: none;
}

.root:focus-within .title {
  text-decoration-line: none;
}

.link {
  composes: reset-link from global;
}

.link[href] .title {
  text-decoration-line: underline;
}

.link:focus-within .title,
.link[href]:hover .title {
  text-decoration-line: none;
}

.imageLink {
  composes: link;
}

.imageLink:focus-within .image {
  outline: var(--outline--focus);
}

.content {
  --color--primary: var(--color--primary--default);
  --color--secondary: var(--color--secondary--default);

  color: var(--color--primary);

  padding: 0 var(--unit--horizontal);

  display: flex;
  flex-direction: column;
  gap: var(--font-small--line-height);
}

.heading {
}

.title {
  composes: font-size-medium from global;
}

.category {
  color: var(--color--secondary);
}
</style>

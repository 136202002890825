import qs from 'qs'

export const queryUploadById = (id: number) => {
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: id
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `upload/files?${query}`
}

<template>
  <i18nLink
    v-if="routeObject"
    :to="routeObject"
    :class="$style.root"
    :data-current-route="active ? '' : null"
    ref="element"
  >
    <slot />
  </i18nLink>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const route = useRoute()

const element = ref(null)

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const linkItem = getLinkItem(props.item)
const name = strapiGetRouteReference(linkItem?.uid)?.name
const routeObject = ref(null)

const routeName = computed(() => nuxtApp.$getRouteBaseName())
const slug = computed(() => route?.params?.slug)
const active = computed(() => {
  const routesWithChildren = [ 'biennials', 'news', 'publications']
  const routeWithChildren = routesWithChildren?.some(i => i?.startsWith(name))
  if (routeWithChildren) {
    const match = routesWithChildren?.find(i => i?.startsWith(name))
    return routeName.value?.startsWith(match)
  } else {
    if (linkItem?.slug) {
      if (linkItem?.slug === slug.value && name === routeName.value) {
        return true
      } else {
        return false
      }
    } else {
      return name === routeName.value

    }
  }
})

if (name === 'biennials') {
  const { data: selectedData } = await useAsyncData(() => {
    return $fetch('/api/strapi-rest',
      { query: { path: queryBiennialThumbnailSelected() } }
    )
  })
  const { data: latestData } = await useAsyncData(() => {
    return $fetch('/api/strapi-rest',
      { query: { path: queryBiennialThumbnailLatest() } }
    )
  })
  if (selectedData.value?.data?.attributes?.currentBiennialEntry?.data?.attributes?.slug) {
    routeObject.value = baseRouteGetObject(
      'biennials-slug-index-about',
      selectedData.value?.data?.attributes?.currentBiennialEntry?.data?.attributes?.slug
    )
  } else if (latestData?.value?.data[0]?.attributes?.slug) {
    routeObject.value = baseRouteGetObject(
      'biennials-slug-index-about',
      latestData?.value?.data[0]?.attributes?.slug
    )
  } else {
    routeObject.value = baseRouteGetObject(
      strapiGetRouteReference(linkItem?.uid)?.name,
      linkItem?.slug
    )
  }
} else {
  routeObject.value = baseRouteGetObject(
    strapiGetRouteReference(linkItem?.uid)?.name,
    linkItem?.slug
  )
}
</script>

<style module>
.root {
  composes: reset-link from global;
}

.root[data-current-route] {
}
</style>

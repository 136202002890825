<template>
  <span
    :class="$style.root"
    v-html="elementsToString"
    ref="element"
    :data-visible="observeVisibility && elementIsVisible || visible ? '' : null"
    :data-animate="animate ? '' : null"
  />
</template>

<script lang="ts" setup>
const props = defineProps ({
  title: {
    type: String,
    required: true
  },
  animate: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: false
  },
  observeVisibility: {
    type: Boolean,
    default: false
  }
})

const element = ref(null)
const elementIsVisible = useElementVisibility(element)

const chunks = props?.title?.split(/(\d+)/g)

const type = (string: string) => {
  if ( string?.match(/(\d+)/g) ) {
    return 'number'
  }
  else {
    return 'text'
  }
}
const parts = chunks?.map((chunk, index) => {
  return {
    type: type(chunk),
    value: chunk
  }
})

const elements = parts?.map((part, index) => {
  const shadow = part?.type === 'number' ? `<span data-shadow>${part?.value}</span>` : ''
  return `<span data-type='${part?.type}'><span class='value'>${shadow}${part?.value}</span></span>`
})

const elementsToString = elements?.join('')

</script>

<style module>
.root {
}
.root [data-type*="number"] {
  position: relative;
}

.root:not([data-animate]) [data-type*="number"] {
  text-decoration-line: underline;
}

.root:not([data-animate]) [data-shadow] {
  display: none;
}

.root[data-animate] [data-shadow] {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;

  color: transparent;

  text-decoration-line: underline;
  clip-path: inset(0 100% -100% 0);
}

.root[data-animate][data-visible] [data-shadow] {
  animation: reveal .1s .75s ease-in-out forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% -100% 0);
  }

  to {
    clip-path: inset(0 0% -100% 0);
  }
}
</style>

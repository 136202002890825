export const queryFragmentMenusItems = {
  items: {
    populate: {
      biennial_entry: {
        fields: ['title', 'slug']
      },
      biennial_page: {
        fields: ['title']
      },
      education_page: {
        fields: ['title']
      },
      home_page: {
        fields: ['title']
      },
      news_page: {
        fields: ['title']
      },
      page_entry: {
        fields: ['title', 'slug']
      },
      press_page: {
        fields: ['title']
      },
      publications_page: {
        fields: ['title']
      },
      urban_vision_page: {
        fields: ['title']
      }
    }
  }
}

export const queryFragmentSeo = {
  seo: {
    populate: {
      image: '*'
    }
  }
}

export const queryFragmentRichText = {
  richText: {
    fields: [
      'richText',
      'richTextTruncated'
    ]
  }
}

export const queryFragmentLink = {
  populate: {
    biennialEntry: {
      fields: ['title', 'slug']
    },
    educationEntry: {
      fields: ['title', 'slug'],
      populate: {
        biennialEntry: {
          fields: ['title', 'slug']
        }
      }
    },
    newsEntry: {
      fields: ['title', 'slug']
    },
    pageEntry: {
      fields: ['title', 'slug']
    },
    publicationEntry: {
      fields: ['slug']
    },
    urbanVisionEntry: {
      fields: ['title', 'slug'],
      populate: {
        biennialEntry: {
          fields: ['title', 'slug']
        }
      }
    },
  }
}

export const queryFragmentBlocks = {
  blocks: {
    populate: {
      links: {
        ...queryFragmentLink
      },
      files: {
        populate: true
      },
      summaries: {
        populate: '*'
      },
      muxAssets: {
        populate: '*'
      },
      downloads: {
        populate: '*'
      },
      ...queryFragmentRichText
    }
  }
}

export const queryFragmentFirstRichTextBlock = {
  'blocks.rich-text': {
    fields: ['richText'],
    filters: {
      richText: {
        $notNull: true
      }
    }
  }
}

export const queryFragmentFirstImages = {
  'blocks.images': {
    populate: {
      files: {
        populate: true
      }
    }
  }
}

export const getBiennialEducationSlugPath = (secondaryslug: string | number, slug: string) => {
  const route = useRoute()
  const publicationStateQuery = baseRouteGetPublicationStateQuery()

  // NOTE this is temp fix: wait for resolve https://github.com/nuxt/nuxt/issues/23232
  if (slug && secondaryslug) {
    const routeObject = {
      name: 'biennials-slug-index-education',
      params: { slug: slug },
      query: { educationSlug: secondaryslug, ...publicationStateQuery }
    }
    // const routeObject = {
    //   name: 'biennials-slug-index-education-secondaryslug',
    //   params: { slug: slug, secondaryslug: secondaryslug },
    //   query: publicationStateQuery
    // }
    return routeObject
  } else if (!slug && secondaryslug) {
    return {
      name: 'index-education-slug',
      params: { slug: secondaryslug },
      query: publicationStateQuery
    }
  }
}

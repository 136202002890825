<template>
  <footer :class="$style.root">
    <div>
      <CoreTextComponent
        v-if="attributes?.richText"
        :string="attributes?.richText"
        :class="$style.richText"
      />
    </div>

    <ul :class="$style.links">
      <li
        v-for="link in attributes?.links"
        :key="link?.id"
      >
        <CoreLinkComponent
          :link="link"
        />
      </li>
    </ul>
  </footer>
</template>

<script setup>
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryFooter() } })
})

const attributes = baseStrapiGetAttributes(data)
</script>

<style module lang="scss">
.root {
  composes: container from global;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--unit--horizontal);
  grid-row-gap: var(--font-small--line-height);

  padding-top: calc(var(--font-small--line-height) * 4);
  margin-bottom: var(--unit--vertical);

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
  }
}

.links {
  composes: reset-list from global;
  columns: 2;
  gap: var(--unit--horizontal);
}
</style>

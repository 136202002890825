<template>
  <div :class="$style.root" :style="cssColorString">
    <div :class="$style.title">
      <i18nLink
        :to="{ name: 'biennials-slug-index-urban-vision', params: { slug: biennialEntry?.slug } }"
        :class="$style.linkTitle"
      >
        <PagesBiennialsEditionsThumbnailHeaderComponent
          :key="biennialEntry?.slug"
          :title="biennialEntry?.title"
          :title-annotation="biennialEntry?.titleAnnotation"
          :date-start="biennialEntry?.dateStart"
          :date-end="biennialEntry?.dateEnd"
          :country-code="biennialEntry?.country"
          :city="biennialEntry?.city"
          :hide-dates="biennialEntry?.hideDates"
          :small-text="true"
        />
      </i18nLink>
    </div>

    <CoreTextComponent
      v-if="firstParagraph"
      :string="firstParagraph"
      :class="$style.firstParagraph"
    />

    <i18nLink
      :to="{ name: 'biennials-slug-index-urban-vision', params: { slug: biennialEntry?.slug } }"
      :class="$style.link"
    >
      {{ $t('shared.more') }}
    </i18nLink>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const biennialEntry = computed(() => props?.entry?.biennialEntry?.data?.attributes || props?.entry?.biennialEntry)
const colors = computed(() => biennialEntry?.value?.colors)
const cssColorString = computed(() => getCssColorString(colors?.value))

const firstParagraph = computed(() => {
  return baseStrapiGetFirstParagraphFromBlocks(props?.entry?.blocks, 40)
})
</script>

<style module>
.root {
  --color--primary: var(--color--primary--default) !important;
  --color--secondary: var(--color--secondary--default) !important;

  color: var(--color--primary);
}

.root * {
  text-decoration-color: var(--color--text-decoration);
}

.link {
  composes: reset-link from global;
}

.title {
  composes: font-size-medium from global;
  margin-bottom: var(--font-small--line-height);
}

.linkTitle :global([class*='heading']) {
  text-decoration-line: underline;
}

.linkTitle:focus-within :global([class*='heading']),
.linkTitle:hover :global([class*='heading']) {
  text-decoration-line: none;
}

.firstParagraph {
}

.linkTitle {
  composes: reset-link from global;
}

.link {
  composes: reset-link link from global;
  display: inline-block;
  text-decoration-line: underline;
}

.root:has(.firstParagraph) .link {
  margin-top: var(--padding--element--top);
}
</style>

<template>
  <nav :class="$style.root" id="nav" ref="element">
    <ul
      :class="$style.items"
      ref="elementItems"
      :data-has-overflow="hasOverflow ? '' : null"
    >
      <li
        v-for="item in items"
        :key="item?.id"
        :class="$style.item"
      >
        <NavigationSiteNavItemComponent
          :item="item"
          :class="$style.item"
        />
      </li>
      <li :class="[$style.item, $style.search]">
        <button
          @click="$emit('onToggleSearch')"
          :class="$style.button"
        >
          {{ $t('search.button.label') }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const element = ref(null)
const elementItems = ref(null)
const { width } = useWindowSize()
const hasOverflow = ref(false)

// 1
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryMenusSiteNav() } }
  )
})

const page = baseStrapiGetAttributes(data)
const items = page?.items?.data

// 2
const setHasOverFlow = () => {
  if (!element.value) { return }
  const scrollWidth = elementItems?.value?.getBoundingClientRect()?.width
  const width = element?.value?.getBoundingClientRect()?.width
  hasOverflow.value = scrollWidth >= width
}

onMounted(() => {
  if (process.client) {
    setHasOverFlow()
  }
})

watch(width, () => {
  if (process.client) {
    nextTick(() => {
      setHasOverFlow()
    })
  }
}, { immediate: true })
</script>

<style module>
.root {
  composes: font-size-large from global;

  padding: var(--unit--vertical) var(--unit--horizontal);
  margin-bottom: calc(var(--font-small--line-height) + (var(--font-small--line-height) - var(--unit--vertical)));

  z-index: var(--z-index--nav);

  position: sticky;
  top: 0;

  user-select: none;

  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-areas: ". items .";
}

.root::-webkit-scrollbar {
  display: none;
}

.items {
  composes: reset-list from global;

  grid-area: items;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: var(--unit--horizontal);
}

.items[data-has-overflow] {
  padding-right: calc(var(--size--button) + var(--unit--horizontal) * 1);
}

.item {
  pointer-events: all;
}

.search {
  composes: button-focus from global;

  padding: var(--padding--button--large);
  background-color: var(--color--secondary);
}

.search:focus-within {
  background-color: var(--color--primary);
  color: var(--color--background);
}

.search:hover {
  background-color: var(--color--primary);
  color: var(--color--background);
}

.button {
  composes: reset-button from global;
}
</style>

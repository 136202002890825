import type { RouterOptions } from '@nuxt/schema'

const scrollIfNeeded = (to, from, savedPosition) => {
  if (to?.path === from?.path) { return }

  const toName = to?.name?.split('___')[0] || null
  const fromName = from?.name?.split('___')[0] || null

  const fromNameBase = fromName?.split('-')[0] || null
  const toNameBase = toName?.split('-')[0] || null

  const biennials = [
    'biennials-slug-index-about',
    'biennials-slug-index-education',
    'biennials-slug-index-insights',
    'biennials-slug-index-participants',
    'biennials-slug-index-urban-vision',
  ]
  const corePages = ['index', 'news', 'publications', ...biennials]

  const modalPages = [
    'biennials-slug-index-education-secondaryslug',
    'biennials-slug-index-participants-index-id',
    'index-education',
    'index-press',
    'index-slug',
    'index-urban-vision',
    'news-slug',
    'publications-slug',
  ]

  if (modalPages?.includes(toName) && fromNameBase === toNameBase || modalPages?.includes(fromName) && fromNameBase === toNameBase || modalPages?.includes(fromName) && toNameBase === 'index' ) { return }
  else {
    return { left: 0, top: 0 }
  }
}

export default <RouterOptions> {
  scrollBehavior (to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce('page:finish', () => {
        resolve({
          ...scrollIfNeeded(to, from, savedPosition)
        })
      })
    })
  }
}

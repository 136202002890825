<template>
  <section v-if="title" :class="[$style.root, smallText ? 'font-size-medium' : 'font-size-large' ]">
    <h1 :class="$style.heading">
      <PagesBiennialsAnimatedTitleComponent
        :animate="true"
        :title="title?.trim()"
        :visible="active"
      />
      <span
        v-if="titleAnnotation"
        v-html="`, ${titleAnnotation?.trim()}`"
        :class="$style.titleAnnotation"
      />
    </h1>
    <div
      v-if="!hideDates"
      :class="$style.dates"
    >
      <CoreDateTimeRangeComponent :date-start="dateStart" :date-end="dateEnd" />
    </div>

    <div
      v-if="country"
      :class="$style.location"
    >
      {{ country }}
    </div>
  </section>
</template>

<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  titleAnnotation: {
    type: String
  },
  dateStart: {
    type: String
  },
  dateEnd: {
    type: String
  },
  countryCode: {
    type: String
  },
  country: {
    type: String,
    required: false
  },
  hideDates: {
    type: Boolean,
    default: false
  },
  smallText: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  }
})

const country = props?.country || i18nCountryNameForCode(props.countryCode)
</script>

<style module>
.root {
  color: var(--color--secondary);
}

.heading {
  composes: reset-heading from global;
  color: var(--color--primary);
}

.titleAnnotation {
  text-transform: lowercase;
  display: inline;
}

.dates,
.location {
}
</style>

<template>
  <li
    v-if="render && label"
    :class="$style.root"
  >
    {{ label }}
  </li>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

const props = defineProps({
  indexUid: {
    type: String,
    required: true
  }
})

const render = computed(() => !props?.indexUid?.endsWith('-page') )

const label = getSearchLabel(props?.indexUid)
</script>

<style module>
.root {
  composes: font-size-large container from global;
  text-align: center;

  margin-top: calc(var(--search--group-label--margin-top, var(--font-default--line-height)) * 2);
  margin-bottom: calc(var(--search--group-label--margin-bottom, var(--font-default--line-height)) * 1);

  color: var(--color--secondary)
}
</style>

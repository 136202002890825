<template>
  <span :class="$style.root">
    <BaseDateTimeRangeComponent
      v-if="dateStart && dateEnd"
      :date-start="toDate(dateStart)"
      :date-end="toDate(dateEnd)"
      format="D MMMM YYYY"
    />
    <BaseDateTimeComponent
      v-else-if="dateStart"
      :date="toDate(dateStart)"
      format="D MMMM YYYY"
    />
  </span>
</template>

<script setup>
const props = defineProps({
  dateStart: {
    type: [Date, String],
    required: true
  },
  dateEnd: {
    type: [Date, String],
    required: false
  }
})

const toDate = (date) => {
  const d = new Date(date)
  return d
}
</script>

<style module lang="scss">
.root {
}

.root :global(.same-year-month-day) {
  :global(.to) {
    :global(.day),
    :global(.day) +
    :global(.sep),

    :global(.month),
    :global(.month) +
    :global(.sep),

    :global(.year),
    :global(.year) +
    :global(.sep) {
      display: none;
    }
  }
}

.root :global(.same-year-month) {
  :global(.from) {
    :global(.month),
    :global(.month) +
    :global(.sep),

    :global(.year),
    :global(.year) +
    :global(.sep) {
      display: none;
    }

    &:has(:global(.hour)) {
      :global(.day) +
      :global(.sep) {
        display: none;
      }

      :global(.year) +
      :global(.sep) {
        display: initial;
      }
    }
  }
}

.root :global(.same-year) {
  :global(.from) {
    :global(.year),
    :global(.year) +
    :global(.sep) {
      display: none;
    }
  }
}
</style>

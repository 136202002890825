import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrow, LazySvgoCross, LazySvgoLine, LazySvgoLogo, LazySvgoMinus, LazySvgoPlus, LazySvgoSearch, LazySvgoTriangle } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrow", LazySvgoArrow],
["SvgoCross", LazySvgoCross],
["SvgoLine", LazySvgoLine],
["SvgoLogo", LazySvgoLogo],
["SvgoMinus", LazySvgoMinus],
["SvgoPlus", LazySvgoPlus],
["SvgoSearch", LazySvgoSearch],
["SvgoTriangle", LazySvgoTriangle],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

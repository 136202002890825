<template>
  <NavigationLinkComponent
    v-if="linkItem"
    :class="$style.root"
    :data-logo="linkItem?.title?.trim()?.toLowerCase() === 'manifesta' ? '' : null"
    :item="item"
  >
    {{ linkItem?.title }}
  </NavigationLinkComponent>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const linkItem = getLinkItem(props.item)

</script>

<style module>
.root {
  composes: font-size-large from global;
  padding-left: 1ch;
  text-indent: -1ch;
  display: inline-block;
}

.root[data-logo] {
  text-transform: uppercase;
  font-feature-settings: "dlig" 1;
}

.root:focus-within,
.root:hover,
.root[data-current-route] {
  text-decoration-line: underline;
}
</style>

<template>
  <div
    v-if="image"
    :class="$style.root"
  >
    <BaseStrapiImageComponent
      :key="image?.id"
      :image="image"
      :class="$style.image"
    />

    <div
      v-if="image?.caption"
      :class="$style.caption"
    >
      {{ caption }}
    </div>
  </div>
  <CoreLightBoxErrorComponent v-else>
    <template #buttonToClose>
      <slot name="buttonToClose" />
    </template>
  </CoreLightBoxErrorComponent>
</template>

<script setup>
const route = useRoute()

const id = computed(() => route?.query['lightbox-image-id'] || 1000000)

const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryUploadById(id?.value) } })
}, { watch: [id] })

const image = computed(() => data?.value?.length ? data?.value[0] : null)

const caption = computed(() => {
  if (!image?.value?.caption) { return null }
  return image?.value?.caption[image?.value?.caption?.length - 1] === '.' ? image?.value?.caption : `${image?.value?.caption?.trim()}.`
})
</script>

<style module>
.root {
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  position: relative;

  color: var(--color--white);
}

.root:focus {
  outline: none;
}

.image {
  flex: 1;
  overflow: hidden;
  --base--image--height: 100%;
  user-select: none;
}

.caption {
  composes: font-size-default from global;
  padding: var(--unit--vertical) var(--unit--horizontal);
  text-align: center;
}
</style>

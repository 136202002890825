import qs from 'qs'

export const queryMenusMenu = () => {
  const query = qs.stringify(
    {
      filters: {
        slug: {
          $eq: 'menu'
        }
      },
      nested: true,
      populate: {
        ...queryFragmentMenusItems
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `menus?${query}`
}
